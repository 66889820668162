.rc-dropdown {
  max-width: min-content;
  .rc-menu {
    @apply shadow-xl border-none overflow-hidden text-xs;
    background-color: rgb(27 32 40);
    padding: .2rem .5rem;
    border-radius: 0 0 .5rem .5rem;
    border: 1px solid rgb(36 40 56);
    border-top: none;

    li.rc-menu-item {
      @apply font-sans py-1.5 px-2;
      background-color: rgb(36 40 56);
      color: white;
      border: none;
      border-radius: 1rem;
      margin: .5rem 0 .5rem 0;
      padding: .5rem;
      //max-width: 200px;

      &-active {
        @apply bg-gray-300;
      }

      &-divider {
        @apply bg-gray-300 h-px;
      }
    }

    li.rc-menu-item-active {
      background-color: rgba(68, 165, 255, 0.14);
    }
  }
}
