@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.Calendar__day.-selectedBetween {
  color: white !important;
}

.small-calendar {
  font-size: 8px !important;
}

/* Style the scrollbar */
.bg-dark-3-container::-webkit-scrollbar {
  width: 10px; /* Adjust width for vertical scrollbar */
}

.bg-dark-3-container::-webkit-scrollbar-track {
  background-color: #2B2B2B; /* Slightly lighter than bg-dark-3 for contrast; adjust as needed */
  border-radius: 5px; /* Make it round, for a more modern look */
}

.bg-dark-3-container::-webkit-scrollbar-thumb {
  background-color: #1A1A1A; /* Assuming this is the color code for bg-dark-3; adjust if different */
  border-radius: 5px; /* Make it round, for a more modern look */
}

.bg-dark-3-container::-webkit-scrollbar-thumb:hover {
  background-color: #0D0D0D; /* Slightly darker shade for a hover effect; adjust as needed */
}

.sticky-header th {
  position: sticky;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
}

.dropdown-list {
  max-height: 200px;  /* Adjust as needed */
  overflow-y: auto;
}

.max-h-50 {
  max-height: 50rem;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's on top of other elements */
}

.tooltip-container {
  cursor: pointer;
}

/* All ScrollBars */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: rgb(27 32 40);
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Maps */
.map {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's on top of other elements */
  border-radius: .5rem;
}

.most_exceptions_icon_padding {
  padding-right: 4px;
}

.rounded-row {
  border-radius: 10px; /* Adjust the radius as needed for your desired roundness */
}

.loading {
  z-index: 9999;
}


.data-grid-container .MuiDataGrid-row {
  border-radius: 80px;
  background-color: #242838;
  margin-top: 4px;
  border-bottom: none;
}

.data-grid-container .MuiDataGrid-cell {
  border: none;
}

/* Removed blue outline around focused cell */
.MuiDataGrid-withBorderColor {
  outline: none !important;
}

.data-grid-container .MuiDataGrid-columnHeaders {
  border-radius: 80px;
  background-color: #242838;
  border-bottom: none;
  margin-bottom: 10px;
}

.data-grid-container {
  height: 50rem;
  border: 0;
}

.selected-row {
  background-color: rgba(255, 69, 0, 0.5) !important;
}

.tooltip-orange {
  color: rgba(255, 69, 0, 0.5) !important;
}

.tooltip-orange:hover {
  color: white !important;
}

.loading-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's on top of other content */
}

/* Dismiss Transaction Toast */
.Toastify__toast-theme--dark {
  background: #1B2028 !important;
}

/* Searchable Dropdown */
.dropdown {
  position: relative;
  color: white;
  font-size: 0.75rem;
}

.small-dropdown {
  width: 110px;
}

.medium-dropdown {
  width: 200px;
}

.parent-dropdown {
  position: absolute; 
  color: white;
  font-size: 0.75rem;
  width: 200px;
}

.type-dropdown {
  position: absolute; 
  color: white;
  font-size: 0.75rem;
  width: 130px;
}

.dropdown .arrow {
  border-color: white transparent transparent;
  border-style: solid;
  border-width: 4px 4px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.15rem;
  margin-right: 0.1rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.dropdown .arrow.open {
  border-color: white transparent transparent;
  border-width: 4px 4px 0;
}

.dropdown .selected-value input {
  background-color: #fff;
  border-radius: 9999px;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
  width: 100%;
  background-color: rgb(36 40 56);
}

.dropdown .selected-value input:focus {
  background-color: rgba(68, 165, 255, 0.14);
  color: white;
}

.dropdown .options {
  display: none;
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  background-color: rgb(27 32 40);
  padding: .2rem .5rem;
  border-radius: 0 0 .5rem .5rem;
  border: 1px solid rgb(36 40 56);
  border-top: none;
  position: absolute;
}

.dropdown .options.open {
  display: block;
  z-index: 9999;
  overflow-y: scroll;
}

.dropdown .option {
  background-color: rgb(36 40 56);
  color: white;
  border: none;
  border-radius: 9999px;
  margin: .5rem 0 .5rem 0;
  padding: .5rem;
}

.dropdown .option.selected,
.dropdown .option:hover {
  background-color: rgba(68, 165, 255, 0.14);
  color: white;
  cursor: pointer;
}

.child-banner {
  height: 50px;
  width: 100%;
  background-color: #1B2028;
  position: fixed;
  top: 0;
  z-index: 1000;
  border-bottom: 1px solid #ccc; /* Light grey border */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: right;
  padding: 0 10px; /* Add padding for spacing */
}

/* Filling info time input */
input[type="time"]::-webkit-calendar-picker-indicator{
  filter: invert(100%);
}

input[type="time"]:hover {
  cursor: pointer;
}

.scrollable {
  overflow-y: auto;
}

.options.slide-down {
  display: block;
  opacity: 1;
  transform: translateY(0);
  animation: slideDown 0.3s ease;
}

@keyframes slideDown {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.options.slide-up {
  opacity: 1;
  display: block;
  animation: slideUp 0.3s ease;
}

@keyframes slideUp {
  0% {
    opacity: 1;
  }
  100% {
    transform: translateY(-10px);
    opacity: 0;
  }
}

.options.closed {
  display: none;
}

