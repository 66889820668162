.Calendar {
  padding-top: 0;
  min-height: auto;
  box-shadow: none;
  color: black;

  &__header {
    padding: 1rem;
  }

  &__section,
  &__weekDays {
    padding: 0 1rem;
  }
}

.calendar-button {
  background-color: white;
  margin-top: -20px;
  border-radius: 10px;
  display: flex;
  justify-content: left;
}
